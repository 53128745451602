<div class="settlements-container">

    <mat-grid-list cols="4" rowHeight="50px" class="settlements-calendar">
        <mat-grid-tile *ngFor="let day of days; " color="primary">
            <mat-checkbox [(checked)]="day.selected" [(ngModel)]="day.selected">{{day.day}}</mat-checkbox>
        </mat-grid-tile>
      </mat-grid-list>

    <button [disabled]="saving" class="save-button" mat-raised-button color="primary" (click)="save()">SAVE</button>
  </div>
