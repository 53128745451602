import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private router: Router){

  }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.id_token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.id_token}`
                }
            });
        }

        return next.handle(request)
                    .pipe(catchError((err,o) =>{
                      if(err instanceof HttpErrorResponse)
                      {
                        if(err.status == 401 && this.router.url !== '/login')
                        {
                            this.router.navigate(['login']);
                        }
                      }
                      return throwError(err);
                    }));
    }
}
