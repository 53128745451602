﻿// tslint:disable-next-line:import-spacing
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// tslint:disable-next-line:import-spacing
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


// tslint:disable-next-line:import-spacing
import { AppComponent } from './app.component';
// tslint:disable-next-line:import-spacing
import { routing } from './app.routing';

import { AuthGuard } from './_guards';
import { JwtInterceptor } from './_helpers';
import { AuthenticationService, AuthorizationService, FeesService } from './_services';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import {
  MatTableModule, MatButtonModule, MatSidenavModule, MatFormFieldModule, MatInputModule,
  MatTabsModule, MatIconModule, MatChipsModule, MatGridListModule, MatCheckboxModule
} from '@angular/material';
import { MerchantsService } from './_services/merchants.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MerchantsComponent } from './merchants';
import { FeesComponent } from './fees';
import { SettlementsService } from './_services/settlements.service';
import { SettlementsComponent } from './settlements';
import { FundsReservationService } from './_services/funds-reservation.service';
import { FundsReservationComponent } from './funds-reservation';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    NgbModule.forRoot(),
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    MatTableModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    MatChipsModule,
    MatGridListModule,
    MatCheckboxModule,
    MatIconModule,
    routing
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    MerchantsComponent,
    FeesComponent,
    SettlementsComponent,
    FundsReservationComponent,
    ConfirmationDialogComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    AuthorizationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    MerchantsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    FeesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    SettlementsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    FundsReservationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    ConfirmationDialogService
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
