﻿import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Merchant } from '../_models/merchants/merchant';
import { FundsReservationService } from '../_services/funds-reservation.service';
import { FundsReservationSettings } from '../_models/funds-reservation/funds-reservation-settings';

export interface Day {
  day: number;
  selected: boolean;
}

@Component({
  selector: 'app-funds-reservation',
  templateUrl: 'funds-reservation.component.html',
  styleUrls: ['./funds-reservation.component.scss']
})
export class FundsReservationComponent implements OnInit, OnChanges {



  constructor(private fundsReservationService: FundsReservationService) {

  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    this.fundsReservationService.getSettings(this.merchant.merchantId)
      .subscribe(data => {
        this.settings = data || {};
      });
  }


  save() {
    this.saving = true;

    if (!this.settings.days || !Number.isInteger(this.settings.days)) {
      this.saving = false;
      return;
    }

    this.fundsReservationService.updateSettings(this.merchant.merchantId, this.settings)
      .subscribe(x => this.saving = false);
  }

  settings: FundsReservationSettings = {};
  saving: boolean = false;
  @Input() merchant: Merchant;
}
