import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FeeRuleMatch } from '../_models/fees/fee_rule_match';
import { FeesContract } from '../_models/fees/fees_contract';

@Injectable()
export class FeesService {

  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public getMatchRules() : Observable<FeeRuleMatch[]>{
      return this.http.get<FeeRuleMatch[]>(`${this.baseUrl}/api/fees/matches`);
  }


  public getMerchantContract(merchantId: string) : Observable<FeesContract>{
    return this.http.get<FeesContract>(`${this.baseUrl}/api/fees/contracts?merchantId=${merchantId}`);
  }

  public updateMerchantContract(merchantId: string, contract: FeesContract): Observable<Object> {
      contract.merchantId = merchantId;
      return this.http.post(`${this.baseUrl}/api/fees/contracts`, contract);
  }
}
