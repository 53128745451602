﻿import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Merchant } from '../_models/merchants/merchant';
import { SettlementsService } from '../_services/settlements.service';
import { SettlementSettings } from '../_models/settlements/settlement-settings';

export interface Day{
  day: number;
  selected : boolean;
}

@Component({
  selector: 'app-settlements',
  templateUrl: 'settlements.component.html',
  styleUrls: ['./settlements.component.scss']
})

export class SettlementsComponent implements OnInit,OnChanges  {



    days : Day[] = [];

    constructor( private settlementService : SettlementsService ) {
        this.init();
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
      this.init();
      this.settlementService.getSettings(this.merchant.merchantId)
                            .subscribe(data => {
                              data.daysInMonth.forEach(x => {
                                this.days[x - 1].selected = true;
                              });
                            });
    }

    private init(){
      this.days = [];
      for (let index = 1; index <= 31; index++) {
        this.days.push({
           day : index,
          selected : false,
        });
      }
    }

    save(){
      this.saving = true;
      let daysInMonth : number[] = [] ;

      this.days.forEach(x => {
          if(x.selected){
           daysInMonth.push(x.day);
          }
      });

      if(daysInMonth.length <= 0){
        this.saving = false;
        return;
      }

      let settings : SettlementSettings = {
        merchantId : this.merchant.merchantId,
        daysInMonth : daysInMonth
      };

      this.settlementService.updateSettings(this.merchant.merchantId,settings)
                            .subscribe(x => {
                              this.saving  = false;
                            });
    }

    saving: boolean = false;
    @Input() merchant : Merchant;
}
