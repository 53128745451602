﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../_models';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthorizationService {

    private baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    isAuthorized() : Promise<boolean> {
      return new Promise((resolve,reject) => {
        var currentUser = localStorage.getItem('currentUser');

        if(!currentUser){
          resolve(false);
            return;
        }

        const user : User =  JSON.parse(currentUser);


        this.http.get<any>(`${this.baseUrl}/api/authorize`)
                      .subscribe(
                        data => resolve(true),
                        err => resolve(false)
                      );
    });
  }
}
