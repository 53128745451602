import { Component, OnInit } from '@angular/core';
import { MerchantBasicInfo } from '../_models/merchants/merchant-basic-info';
import { MatTableDataSource } from '@angular/material';
import { MerchantsService } from '../_services/merchants.service';
import { Merchant } from '../_models/merchants/merchant';
import { SelectionModel } from '@angular/cdk/collections';
import { MerchantApplication } from '../_models/merchants/merchant-appilcation';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

@Component({
  templateUrl: 'merchants.component.html',
  styleUrls: ['./merchants.component.scss']
})
export class MerchantsComponent implements OnInit {


  merchants: MatTableDataSource<MerchantBasicInfo>;
  selection = new SelectionModel<string>(false, null);
  searchTerm: string;

  columnsToDisplay = [
    "clearMerchant",
    "merchantId",
    "merchantExternalId",
    "merchantName"
  ];

  DISABLE_MERCHANT_TEXT = 'Are you sure you want to disable this merchant and remove all of its inventory from the marketplace?';
  ENABLE_MERCHANT_TEXT = 'Are you sure you want to enable this merchant and bring back all of its inventory to our marketplace?';


  constructor(
    private merchantsService: MerchantsService,
    private confirmationDialogService: ConfirmationDialogService
  ) {

  }

  ngOnInit() {
    this.merchants = new MatTableDataSource<MerchantBasicInfo>();
    this.merchants.filterPredicate = this.filterPredicate;

    this.merchantsService.getMerchants().subscribe(data => {
      this.merchants.data = data;
    });
  }

  search(term: string) {
    this.merchants.filter = term;
  }

  private filterPredicate(merchant: MerchantBasicInfo, filter: string): boolean {
    filter = filter.toLowerCase();

    if (merchant.merchantId && merchant.merchantId.toLocaleLowerCase().indexOf(filter) != -1) { return true };
    if (merchant.externalId && merchant.externalId.toLocaleLowerCase().indexOf(filter) != -1) { return true };
    if (merchant.sellerInformation && merchant.sellerInformation.displayName && merchant.sellerInformation.displayName.toLocaleLowerCase().indexOf(filter) != -1) { return true };

    return false;
  }

  private initialize(merchant: Merchant) {

    if (!merchant.businessInformation) {
      merchant.businessInformation = {};
    }

    if (!merchant.commonBusinessInformation) {
      merchant.commonBusinessInformation = {};
    }
    if (!merchant.contactDetails) {
      merchant.contactDetails = {};
    }
    if (!merchant.storeDetails) {
      merchant.storeDetails = {};
    }
    if (!merchant.businessInformation.bankAccountDetails) {
      merchant.businessInformation.bankAccountDetails = {};
    }

    if (!merchant.businessInformation.businessAddress) {
      merchant.businessInformation.businessAddress = {};
    }

    if (!merchant.businessInformation.businessDetails) {
      merchant.businessInformation.businessDetails = {};
    }

    if (!merchant.storeDetails.returnAddress) {
      merchant.storeDetails.returnAddress = {};
    }

    if (!merchant.storeDetails.sellerInformation) {
      merchant.storeDetails.sellerInformation = {};
    }

    if (!merchant.storeDetails.storeInformation) {
      merchant.storeDetails.storeInformation = {};
    }


    merchant.businessInformation.bankAccountDetails.bankVerificationDocumentUrl =
      merchant.businessInformation.bankAccountDetails.bankVerificationDocumentUrl || null;

    merchant.businessInformation.businessDetails.companyType =
      merchant.businessInformation.businessDetails.companyType || "Public";

    merchant.businessInformation.businessDetails.countryOfIncorporation =
      merchant.businessInformation.businessDetails.countryOfIncorporation || "GB";

    merchant.businessInformation.businessAddress.countryCode =
      merchant.businessInformation.businessAddress.countryCode || "GB";
  }

  chooseMerchant(merchant: MerchantBasicInfo) {
    this.selection.select(merchant.merchantId)
    this.merchantsService.getMerchant(merchant.merchantId).subscribe(merchant => {
      this.initialize(merchant);
      this.currentMerchant = merchant;
    });

    this.merchantsService.getMerchantApplication(merchant.merchantId).subscribe(application => {
      this.currentApplication = application;
    });
  }

  private getMerchantIdx(merchant: MerchantBasicInfo) {
    return this.merchants.data.findIndex(m => m.merchantId === merchant.merchantId);
  }

  disable(merchantId: string) {
    this.merchantsService.disable(merchantId)
      .subscribe(x => {
        this.merchantsService.getMerchants()
          .subscribe(data => {
            this.merchants.data = data;
            this.selection.clear();
            this.currentMerchant = null;
          });
      });
  }

  saveBussinessInformation() {
    this.saving = true;

    this.merchantsService.updateBusinessInformation(
      this.currentMerchant.merchantId,
      this.currentMerchant.businessInformation)
      .subscribe(x => {
        this.merchantsService.getMerchants()
          .subscribe(data => {
            this.merchants.data = data;
            this.selection.select(this.currentMerchant.merchantId);
            this.saving = false;
          });
      });
  }

  saveStoreDetails() {
    this.saving = true;
    this.merchantsService.updateStoreDetails(
      this.currentMerchant.merchantId,
      this.currentMerchant.storeDetails,
      this.currentMerchant.commonBusinessInformation)
      .subscribe(x => {
        this.merchantsService.getMerchants()
          .subscribe(data => {
            this.merchants.data = data;
            this.selection.select(this.currentMerchant.merchantId);
            this.saving = false;
          });
      });
  }
  toggleMerchantStatus() {
    this.deleting = true;
    this.saving = true;
    this.confirmationDialogService.confirm('Please confirm', this.currentMerchant.disabled ?
      this.ENABLE_MERCHANT_TEXT :
      this.DISABLE_MERCHANT_TEXT,
      'Yes', 'No')
      .then((confirmed) => {
        this.merchantsService.toggleMerchant(this.currentMerchant.merchantId, !this.currentMerchant.disabled)
          .subscribe(data => {
            this.merchantsService.getMerchants()
              .subscribe(data => {
                this.merchants.data = data;
                this.merchantsService.getMerchant(this.currentMerchant.merchantId).subscribe(merchant => {
                  this.initialize(merchant);
                  this.currentMerchant = merchant;
                  this.deleting = false;
                  this.saving = false;
                })
              })
          });
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  saveContactDetails() {
    this.saving = true;
    this.merchantsService.updateContactDetails(
      this.currentMerchant.merchantId,
      this.currentMerchant.contactDetails)
      .subscribe(x => {
        this.merchantsService.getMerchants()
          .subscribe(data => {
            this.merchants.data = data;
            this.selection.select(this.currentMerchant.merchantId);
            this.saving = false;
          });
      });
  }

  createApplication() {
    this.saving = true;
    this.merchantsService.createMerchantApplication(this.currentMerchant.merchantId)
      .subscribe(x => {
        this.merchantsService.getMerchants()
          .subscribe(data => {
            this.merchants.data = data;
            this.selection.select(this.currentMerchant.merchantId);
            this.saving = false;
          });
      });

  }


  createCAToken() {
    this.saving = true;
    this.merchantsService.createCAToken(this.currentMerchant.merchantId)
      .subscribe(x => {
        this.caToken = x.access_token;
        this.merchantsService.getMerchants()
          .subscribe(data => {
            this.merchants.data = data;
            this.selection.select(this.currentMerchant.merchantId);
            this.saving = false;
          });
      });

  }

  caToken: string;
  deleting: boolean = false;
  saving: boolean = false;
  currentMerchant: Merchant;
  currentApplication: MerchantApplication;
}
