<div class="fees-container">
    <mat-table [dataSource]="rules">

        <ng-container matColumnDef="matchName">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let rule">
              {{matches[rule.feeRuleMatchId]?.match}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="minFees">
          <mat-header-cell *matHeaderCellDef> Min Fees </mat-header-cell>
          <mat-cell *matCellDef="let rule">
            <mat-form-field class="fee-rule-form-field">
                <input matInput type="number" step="0.01" [(ngModel)]="rule.strategyConfiguration.parameters.minFees"
                value="rule.strategyConfiguration.parameters.minFees">
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="percentage">
          <mat-header-cell *matHeaderCellDef> Pecentage </mat-header-cell>
          <mat-cell *matCellDef="let rule">
            <mat-form-field class="fee-rule-form-field">
                <input matInput type="number" step="0.01"
                  [(ngModel)]="rule.strategyConfiguration.parameters.percentage"
                  value="rule.strategyConfiguration.parameters.percentage">
            </mat-form-field>
          </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row *matRowDef="let myRowData; columns: columnsToDisplay;" class="table-row">
        </mat-row>
    </mat-table>

    <div class="vat-container">
      <mat-checkbox [(checked)]="applyVAT" [(ngModel)]="applyVAT">
        Apply VAT (20%)
      </mat-checkbox>
    </div>

    <button [disabled]="savingVat||savingContract" class="save-button" mat-raised-button color="primary" (click)="save()">SAVE</button>
</div>

