import { Injectable } from '@angular/core';
import { MerchantBasicInfo } from '../_models/merchants/merchant-basic-info';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { Merchant } from '../_models/merchants/merchant';
import { SellerInformation } from '../_models/merchants/seller-information';
import { MerchantSettingsUpdateRequest } from '../_models/merchants/update-request';
import { BusinessInformation } from '../_models/merchants/business-information';
import { CommonBusinessInformation } from '../_models/merchants/common-business-information';
import { StoreDetails } from '../_models/merchants/store-details';
import { ContactDetails } from '../_models/merchants/contact-details';
import { MerchantApplication } from '../_models/merchants/merchant-appilcation';
import { Token } from '../_models/merchants/token';

@Injectable()
export class MerchantsService {


  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }


  public getMerchants(): Observable<MerchantBasicInfo[]> {
    return this.http.get<MerchantBasicInfo[]>(`${this.baseUrl}/api/merchants`);
  }


  public getMerchant(merchantId: string): Observable<Merchant> {
    return this.http.get<Merchant>(`${this.baseUrl}/api/merchants/${merchantId}`);
  }

  public updateSellerInformation(merchantId: string, info: SellerInformation): Observable<Object> {
    const request: MerchantSettingsUpdateRequest = {
      sellerInformation: info
    };
    return this.http.put(`${this.baseUrl}/api/merchants/${merchantId}`, request);
  }

  public updateBusinessInformation(merchantId: string, info: BusinessInformation): Observable<Object> {
    const request: MerchantSettingsUpdateRequest = {
      businessInformation: info
    };
    return this.http.put(`${this.baseUrl}/api/merchants/${merchantId}`, request);
  }


  public updateStoreDetails(merchantId: string, details: StoreDetails, info: CommonBusinessInformation): Observable<Object> {
    const request: MerchantSettingsUpdateRequest = {
      storeDetails: details,
      commonBusinessInformation: info
    };
    return this.http.put(`${this.baseUrl}/api/merchants/${merchantId}`, request);
  }

  public updateContactDetails(merchantId: string, details: ContactDetails): Observable<Object> {
    const request: MerchantSettingsUpdateRequest = {
      contactDetails: details
    };
    return this.http.put(`${this.baseUrl}/api/merchants/${merchantId}`, request);
  }

  public updateMerchant(merchantId: string, data: Partial<Merchant>): Observable<Merchant> {
    return this.http.put(`${this.baseUrl}/api/merchants/${merchantId}`, data);
  }

  public disable(merchantId: string): Observable<Object> {
    return this.http.post(`${this.baseUrl}/api/merchants/${merchantId}/disable`, {});
  }


  public getMerchantApplication(merchantId: string): Observable<MerchantApplication> {
    return this.http.get<MerchantApplication>(`${this.baseUrl}/api/merchants/${merchantId}/application`);
  }


  public createMerchantApplication(merchantId: string): Observable<Object> {
    return this.http.post<Merchant>(`${this.baseUrl}/api/merchants/${merchantId}/application`, {});
  }

  public createCAToken(merchantId: string): Observable<Token> {
    return this.http.post<Token>(`${this.baseUrl}/api/merchants/${merchantId}/ca-token`, {});
  }

  public toggleMerchant(merchantId: string, disabled: boolean): Observable<Object> {
    return this.http.put<Merchant>(`${this.baseUrl}/api/merchants/${merchantId}`, { disabled: disabled });
  }
}
