import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FundsReservationSettings } from '../_models/funds-reservation/funds-reservation-settings';

@Injectable()
export class FundsReservationService {


  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }


  public getSettings(merchantId : string) : Observable<FundsReservationSettings>{
      return this.http.get<FundsReservationSettings>(`${this.baseUrl}/api/funds-reservation/settings?merchantId=${merchantId}`);
  }

  public updateSettings(merchantId: string ,settings : FundsReservationSettings ) : Observable<Object> {
    settings.merchantId = merchantId;
    return this.http.post(`${this.baseUrl}/api/funds-reservation/settings`,settings);
  }
}
