import { Component, OnInit, Input, OnChanges, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material';

import { FeesService, MerchantsService } from '../_services';
import { FeeRule } from '../_models/fees/fee_rule';
import { FeesContract } from '../_models/fees/fees_contract';
import { FeeRuleMatch } from '../_models/fees/fee_rule_match';
import { Merchant } from '../_models/merchants/merchant';

@Component({
  selector: 'app-fees',
  templateUrl: 'fees.component.html',
  styleUrls: ['./fees.component.scss']
})
export class FeesComponent implements OnInit, OnChanges {

  rules: MatTableDataSource<FeeRule>;

  columnsToDisplay = [
    "matchName",
    "minFees",
    "percentage"
  ];

  matches: { [id: string]: FeeRuleMatch } = {};

  matchesReady: Promise<{}>;
  applyVAT = false;

  constructor(
    private feesService: FeesService,
    private merchantsService: MerchantsService
  ) {
    this.matchesReady = new Promise(resolve => {
      this.feesService.getMatchRules().subscribe(data => {
        data.forEach(x => this.matches[x.id] = x);
        resolve();
      });
    });
  }

  ngOnInit(): void {
    this.onPropsUpdated();
  }

  ngOnChanges() {
    this.onPropsUpdated();
    this.rules = new MatTableDataSource<FeeRule>()
    this.matchesReady.then(_ => {
      this.feesService.getMerchantContract(this.merchant.merchantId).subscribe(data2 => {
        this.contract = data2;

        if (!data2 || !data2.referralFees) {
          return;
        }

        const rules: FeeRule[] = [];

        if (data2.referralFees.rules) {
          data2.referralFees.rules.forEach(x => rules.push(x));
        }

        if (data2.referralFees.defaultRule) {
          rules.push(data2.referralFees.defaultRule);
        }

        this.rules.data = rules;
      });
    });
  }

  save() {
    this.saveApplyVat();
    this.saveContract();
  }

  saveApplyVat() {
    if (this.applyVAT !== this.merchant.applyVat) {
      this.savingVat = true;
      this.merchant.applyVat = this.applyVAT;
      this.merchantsService.updateMerchant(this.merchant.merchantId, {
        applyVat: this.applyVAT
      }).subscribe(() => {
        this.savingVat = false;
      });
    }
  }

  saveContract() {
    if (this.contract !== null) {
      this.savingContract = true;
      this.feesService.updateMerchantContract(this.merchant.merchantId, this.contract).subscribe(() => {
        this.savingContract = false;
      });
    }
  }

  private onPropsUpdated() {
    this.applyVAT = this.merchant.applyVat || false;
  }

  savingVat: boolean = false;
  savingContract: boolean = false;
  contract: FeesContract;

  @Input() merchant: Merchant;
}
