<mat-sidenav-container class="merchants-side-nav">
  <mat-sidenav mode="side" opened style="width: 500px;text-align: center">
    <mat-form-field style="padding-top:15px;width: 80%">
      <input matInput type="text" placeholder="Search..." (keyup)="search($event.target.value)" />
      <button mat-button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="searchTerm=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div class="mat-elevation-z8 table-container">
      <mat-table [dataSource]="merchants">

        <ng-container matColumnDef="clearMerchant">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
          <mat-cell *matCellDef="let merchant">
            <button mat-icon-button matTooltip="Clear" (click)="disable(merchant.merchantId)">
              <mat-icon style="font-size: 18px;">clear</mat-icon>
            </button>
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="merchantId">
          <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
          <mat-cell *matCellDef="let merchant"> {{merchant.merchantId}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="merchantExternalId">
          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
          <mat-cell *matCellDef="let merchant"> {{merchant.externalId}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="merchantName">
          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
          <mat-cell *matCellDef="let merchant"> {{merchant.sellerInformation.displayName}} </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row *matRowDef="let myRowData; columns: columnsToDisplay;"
          [ngClass]="{ 'selected': selection.isSelected(myRowData.merchantId)}" class="table-row"
          (click)="chooseMerchant(myRowData)">

        </mat-row>
      </mat-table>

    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div *ngIf="currentMerchant; then form else choose"></div>

    <ng-template #form>
      <mat-tab-group>
        <mat-tab label="Merchant Details">
          <div class="merchants-tab">
            <mat-form-field class="merchant-form-field">
              <input name="displayName" matInput placeholder="Display Name"
                [(ngModel)]="currentMerchant.storeDetails.sellerInformation.displayName">
            </mat-form-field>
            <br />
            <mat-form-field class="merchant-form-field">
              <input name="customerServiceEmail" matInput placeholder="Customer Service Email"
                [(ngModel)]="currentMerchant.storeDetails.sellerInformation.customerServiceEmail">
            </mat-form-field>
            <br />
            <mat-form-field class="merchant-form-field">
              <textarea name="returnAndRefundPolicy" matInput placeholder="Return and Refund Policy"
                [(ngModel)]="currentMerchant.storeDetails.sellerInformation.returnAndRefundPolicy">
                        </textarea>
            </mat-form-field>
            <br />
            <mat-form-field class="merchant-form-field">
              <textarea name="about" matInput placeholder="About"
                [(ngModel)]="currentMerchant.storeDetails.sellerInformation.about">
                        </textarea>
            </mat-form-field>
            <br />
            <mat-form-field class="merchant-form-field">
              <div style="text-align: center">
                <img style="padding-bottom: 10px; max-height: 150px; width: auto; "
                  src="{{currentMerchant.storeDetails.sellerInformation.logoUrl}}"
                  *ngIf="currentMerchant.storeDetails.sellerInformation.logoUrl">
              </div>
              <input name="logoUrl" matInput placeholder="Logo Url"
                [(ngModel)]="currentMerchant.storeDetails.sellerInformation.logoUrl">
            </mat-form-field>
            <h6>Return Address</h6>
            <mat-form-field class="merchant-form-field">
              <input name="return-address1" matInput placeholder="Address 1"
                [(ngModel)]="currentMerchant.storeDetails.returnAddress.address1">
            </mat-form-field>
            <mat-form-field class="merchant-form-field">
              <input name="return-address2" matInput placeholder="Address 2"
                [(ngModel)]="currentMerchant.storeDetails.returnAddress.address2">
            </mat-form-field>
            <mat-form-field class="merchant-form-field">
              <input name="return-city" matInput placeholder="City"
                [(ngModel)]="currentMerchant.storeDetails.returnAddress.city">
            </mat-form-field>
            <mat-form-field class="merchant-form-field">
              <input name="return-state" matInput placeholder="State"
                [(ngModel)]="currentMerchant.storeDetails.returnAddress.state">
            </mat-form-field>
            <mat-form-field class="merchant-form-field">
              <input name="return-countryCode" matInput placeholder="Country Code"
                [(ngModel)]="currentMerchant.storeDetails.returnAddress.countryCode">
            </mat-form-field>
            <mat-form-field class="merchant-form-field">
              <input name="return-postCode" matInput placeholder="Post Code"
                [(ngModel)]="currentMerchant.storeDetails.returnAddress.postcode">
            </mat-form-field>
            <div class="actions-area">
              <button *ngIf="currentMerchant.disabled" [disabled]="updating" class="success-button" mat-raised-button
                (click)="toggleMerchantStatus()">ENABLE</button>
              <button *ngIf="!currentMerchant.disabled" [disabled]="updating" class="delete-button" mat-raised-button
                color="warn" (click)="toggleMerchantStatus()">DISABLE</button>
              <button [disabled]="saving" class="save-button" mat-raised-button color="primary"
                (click)="saveStoreDetails()">SAVE</button>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Bank Account Information" class="merchants-tab">
          <div class="merchants-tab">
            <mat-form-field class="merchant-form-field">
              <input name="accountNumber" matInput placeholder="Account Number"
                [(ngModel)]="currentMerchant.businessInformation.bankAccountDetails.accountNumber">
            </mat-form-field>
            <mat-form-field class="merchant-form-field">
              <input name="bankVerificationDocumentUrl" matInput placeholder="Bank Verification Document Url"
                [(ngModel)]="currentMerchant.businessInformation.bankAccountDetails.bankVerificationDocumentUrl">
            </mat-form-field>
            <mat-form-field class="merchant-form-field">
              <input name="banklocation" matInput placeholder="Bank Location"
                [(ngModel)]="currentMerchant.businessInformation.bankAccountDetails.location">
            </mat-form-field>
            <mat-form-field class="merchant-form-field">
              <input name="sortCode" matInput placeholder="Sort Code"
                [(ngModel)]="currentMerchant.businessInformation.bankAccountDetails.sortCode">
            </mat-form-field>
            <button [disabled]="saving" class="save-button" mat-raised-button color="primary"
              (click)="saveBussinessInformation()">SAVE</button>
          </div>
        </mat-tab>
        <mat-tab label="Bussiness Information" class="merchants-tab">
          <div class="merchants-tab">
            <mat-form-field class="merchant-form-field">
              <input name="legalBussinesName" matInput placeholder="Legal Bussiness Name"
                [(ngModel)]="currentMerchant.businessInformation.businessDetails.legalBusinessName">
            </mat-form-field>
            <mat-form-field class="merchant-form-field">
              <input name="companyType" matInput placeholder="Company Type"
                [(ngModel)]="currentMerchant.businessInformation.businessDetails.companyType">
            </mat-form-field>
            <mat-form-field class="merchant-form-field">
              <input name="companyNumber" matInput placeholder="Company Number"
                [(ngModel)]="currentMerchant.businessInformation.businessDetails.companyNumber">
            </mat-form-field>
            <mat-form-field class="merchant-form-field">
              <input name="countryOfIncorporation" matInput placeholder="Country of Incorporation"
                [(ngModel)]="currentMerchant.businessInformation.businessDetails.countryOfIncorporation">
            </mat-form-field>

            <h6>Registred Business Address</h6>
            <mat-form-field class="merchant-form-field">
              <input name="bussiness-address1" matInput placeholder="Address 1"
                [(ngModel)]="currentMerchant.businessInformation.businessAddress.address1">
            </mat-form-field>
            <mat-form-field class="merchant-form-field">
              <input name="bussiness-address2" matInput placeholder="Address 2"
                [(ngModel)]="currentMerchant.businessInformation.businessAddress.address2">
            </mat-form-field>
            <mat-form-field class="merchant-form-field">
              <input name="bussiness-city" matInput placeholder="City"
                [(ngModel)]="currentMerchant.businessInformation.businessAddress.city">
            </mat-form-field>
            <mat-form-field class="merchant-form-field">
              <input name="bussiness-state" matInput placeholder="State"
                [(ngModel)]="currentMerchant.businessInformation.businessAddress.state">
            </mat-form-field>
            <mat-form-field class="merchant-form-field">
              <input name="bussiness-countryCode" matInput placeholder="Country Code"
                [(ngModel)]="currentMerchant.businessInformation.businessAddress.countryCode">
            </mat-form-field>
            <mat-form-field class="merchant-form-field">
              <input name="bussiness-postCode" matInput placeholder="Post Code"
                [(ngModel)]="currentMerchant.businessInformation.businessAddress.postcode">
            </mat-form-field>
            <button [disabled]="saving" class="save-button" mat-raised-button color="primary"
              (click)="saveBussinessInformation()">SAVE</button>
          </div>
        </mat-tab>
        <mat-tab label="Store Details" class="merchants-tab">
          <div class="merchants-tab">
            <h6>Store Information</h6>
            <mat-form-field class="merchant-form-field">
              <input name="storeName" matInput placeholder="Store Name"
                [(ngModel)]="currentMerchant.storeDetails.storeInformation.storeName">
            </mat-form-field>
            <mat-form-field class="merchant-form-field">
              <input name="storeWebsiteUrl" matInput placeholder="Store Website Url"
                [(ngModel)]="currentMerchant.storeDetails.storeInformation.storeWebsiteUrl">
            </mat-form-field>
            <mat-form-field class="merchant-form-field">
              <input name="mainCategory" matInput placeholder="Main Category"
                [(ngModel)]="currentMerchant.storeDetails.storeInformation.mainCategory">
            </mat-form-field>

            <mat-form-field class="merchant-form-field">
              <input name="topSellingBrands" matInput placeholder="Top Selling Brands"
                [(ngModel)]="currentMerchant.commonBusinessInformation.topSellingBrands">
            </mat-form-field>

            <button [disabled]="saving" class="save-button" mat-raised-button color="primary"
              (click)="saveStoreDetails()">SAVE</button>
          </div>
        </mat-tab>

        <mat-tab label="Representative Details" class="merchants-tab">
          <div class="merchants-tab">
            <mat-form-field class="merchant-form-field">
              <input name="representativeFullName" matInput placeholder="Full Name"
                [(ngModel)]="currentMerchant.contactDetails.representativeFullName">
            </mat-form-field>

            <div class="horizontal-form-group">
              <mat-form-field class="merchant-form-field country-code">
                <input name="RepresentativePhoneCountryCode" matInput placeholder="Country Code"
                  [(ngModel)]="currentMerchant.contactDetails.representativePhoneCountryCode">
              </mat-form-field>

              <mat-form-field class="merchant-form-field phone-number">
                <input name="representativePhoneNumber" matInput placeholder="Phone Number"
                  [(ngModel)]="currentMerchant.contactDetails.representativePhoneNumber">
              </mat-form-field>

            </div>

            <mat-form-field class="merchant-form-field">
              <input name="representativeEmail" matInput placeholder="Email"
                [(ngModel)]="currentMerchant.contactDetails.representativeEmail">
            </mat-form-field>

            <button [disabled]="saving" class="save-button" mat-raised-button color="primary"
              (click)="saveContactDetails()">SAVE</button>
          </div>
        </mat-tab>

        <mat-tab label="Channel Advisor Token" class="merchants-tab">
          <div class="merchants-tab">
            <button [disabled]="saving" class="save-button" mat-raised-button color="primary"
              (click)="createCAToken()">CREATE ACESSS TOKEN</button>
            <div style="padding-top: 50px">
              <mat-form-field class="merchant-form-field">
                <textarea name="caToken" matInput placeholder="Channel Advisor Token" [value]="caToken" cols="50"
                  rows="30" disabled>
                    </textarea>
              </mat-form-field>
            </div>

          </div>
        </mat-tab>

        <mat-tab label="Public API Credentials" class="merchants-tab">
          <div class="merchants-tab">
            <ng-container *ngIf="currentApplication; then showApplication; else suggestCreate"></ng-container>
            <ng-template #showApplication>
              <mat-form-field class="merchant-form-field">
                <input name="applicationId" matInput placeholder="Application Id" [value]="currentApplication.id"
                  disabled>
              </mat-form-field>

              <mat-form-field class="merchant-form-field">
                <input name="clientId" matInput placeholder="Client Id" disabled [value]="currentApplication.clientId">
              </mat-form-field>

              <mat-form-field class="merchant-form-field">
                <input name="clientSecret" matInput placeholder="Client Secret" disabled
                  [value]="currentApplication.clientSecret">
              </mat-form-field>
            </ng-template>
            <ng-template #suggestCreate>
              <button [disabled]="saving" class="save-button" mat-raised-button color="primary"
                (click)="createApplication()">CREATE</button>
            </ng-template>
          </div>
        </mat-tab>
        <mat-tab label="Fees Settings" class="merchants-tab">
          <app-fees [merchant]="currentMerchant"></app-fees>
        </mat-tab>

        <mat-tab label="Settlements Settings" class="merchants-tab">
          <app-settlements [merchant]="currentMerchant"></app-settlements>
        </mat-tab>

        <mat-tab label="Funds Reservation Settings" class="merchants-tab">
          <app-funds-reservation [merchant]="currentMerchant"></app-funds-reservation>
        </mat-tab>
      </mat-tab-group>
    </ng-template>
    <ng-template #choose>
      <div class="no-merchants">
        <h2>
          Choose a merchant from the list on the left
        </h2>
      </div>
    </ng-template>

  </mat-sidenav-content>
</mat-sidenav-container>
