﻿import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthorizationService } from '../_services';
@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,private auth: AuthorizationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return new Promise<boolean>((resolve,reject) =>{
        this.auth.isAuthorized()
                .then(b => {
                  if(!b) {
                    this.router.navigate(['login']);
                    resolve(false);
                    return;
                  }
                  resolve(true);
                });
      });
    }
}
