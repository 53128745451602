﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../_services';

@Component({templateUrl: 'login.component.html'})
export class LoginComponent implements OnInit {

    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService) {
      }

    ngOnInit() {
        // reset login status

        this.authenticationService.signOut();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    signIn() {
        this.authenticationService.signIn()
                                .then(data => {
                                    this.router.navigate([this.returnUrl]);
                                  },
                                  error => {
                                    this.error = error;
                                    this.loading = false;
                                });

    }

}
