<style>
      .menu {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center
      }
      .menu-item{
        font-size: 24px;
        font-weight: bold;
        padding: 10px 0px;
      }

</style>

<div class="menu">

  <h2>
    Market Administration
  </h2>
  <div class="menu-item"></div>
  <div class="menu-item"></div>
  <div class="menu-item">
    <a routerLink="/merchants">Merchants Administration</a>
  </div>

  <div class="menu-item">
    Products Adminstartion
  </div>
</div>
