<div class="funds-container">

    <mat-form-field class="reservation-from form-field">
        <input matInput placeholder="Reservation Time" type="text"
                        [(ngModel)]="settings.fromTime"
                        value="settings.fromTime" disabled>
    </mat-form-field>

    <mat-form-field class="reservation-percentage form-field">
        <input matInput type="number" step="0.01"
          placeholder="Reservation Percentage"
          [(ngModel)]="settings.percentage"
          value="settings.percentage">
    </mat-form-field>

    <mat-form-field class="reservation-days form-field">
        <input matInput type="number" step="1"
          placeholder="Reservation Days"
          [(ngModel)]="settings.days"
          value="settings.days">
    </mat-form-field>



    <button [disabled]="saving" class="save-button" mat-raised-button color="primary" (click)="save()">SAVE</button>
  </div>
