﻿import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../_models/user';
import { environment } from '../../environments/environment';

declare let gapi: any;

@Injectable()
export class AuthenticationService {

    constructor(private http: HttpClient) {

    }

    private url  = 'https://apis.google.com/js/platform.js';

    private auth2: any;


    private loadScript(): Promise<{}> {

        return new Promise((resolve, reject) => {

          if (document.getElementById('google')) {
            resolve(this.auth2);
            return;
          }

          const signInJS = document.createElement('script');
          signInJS.async = true;
          signInJS.src = this.url;
          signInJS.onload = () => {
            gapi.load('auth2', () => {
              this.auth2 = gapi.auth2.init({
                client_id: environment.clientId,
                scope: 'email',
                fetch_basic_profile: false
              });

              resolve(this.auth2);
            });
          };
          document.head.appendChild(signInJS);

        });

      }


    private setAndGetUser(): User {

      const profile = this.auth2.currentUser.get().getBasicProfile();
      const authResponseObj = this.auth2.currentUser.get().getAuthResponse(true);

      const user: User = {
        id: profile.getId(),
        name: profile.getName(),
        email:  profile.getEmail(),
        image: profile.getImageUrl(),
        expires_in: authResponseObj.expires_in,
        token: authResponseObj.access_token,
        id_token:  authResponseObj.id_token,
      };

      localStorage.setItem('currentUser', JSON.stringify(user));

      return user;
    }

    signIn(): Promise<User> {
      return new Promise((resolve, reject) => {
        this.loadScript().then(a => {

          if (this.auth2.isSignedIn.get()) {
            return;
          }
          const promise = this.auth2.signIn();

          promise.then(() => {
            resolve(this.setAndGetUser());
          });
        });

      });
    }

    signOut() {
      return new Promise((resolve, reject) => {
        this.loadScript().then(a => {

          if (!this.auth2.isSignedIn.get()) {
            return;
          }
          const promise = this.auth2.signOut();

          promise.then((err) => {
            if (!err) {

              localStorage.removeItem('currentUser');
              resolve();
              return ;
            }
            reject(err);
          });
        });

      });
    }
}
